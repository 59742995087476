import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { FilterService } from '../filter/filter.service';
import { PAGES_TAB, DASHBOARD_NAME } from '../../constants/common-constants';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { TableService } from '../table/table.service';
import { BaseService } from '../base/base.service';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class DashletService {
  private _pageName = 'Producers&Markets';
  private _dashboardName = DASHBOARD_NAME;
  private menuTabs = [...PAGES_TAB];
  chartInstanceSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  dashletDataSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  selectedPage: BehaviorSubject<any> = new BehaviorSubject(null);
  exhibitGwpValue: BehaviorSubject<any> = new BehaviorSubject(0);
  firstYearGwpValue: BehaviorSubject<any> = new BehaviorSubject(0);
  secondYearGwpValue: BehaviorSubject<any> = new BehaviorSubject(0);
  thirdYearGwpValue: BehaviorSubject<any> = new BehaviorSubject(0);
  fourthYearGwpValue: BehaviorSubject<any> = new BehaviorSubject(0);
  fifthYearGwpValue: BehaviorSubject<any> = new BehaviorSubject(0);
  public dataFormat: BehaviorSubject<any> = new BehaviorSubject('chart');
  // Variable to store all active dashlet info -> name, title, tableData, chartinstance
  private _activeDashletTypes = {};

  isPDFDisable = new BehaviorSubject(false);
  globalPayload;
  constructor(
    private apiService: ApiService,
    private filterService: FilterService,
    private tableService: TableService,
    private baseService: BaseService
  ) {
    // let selection = this.menuTabs.find(item => item.route === this.router.url);
    // if (this.router.url === this.renewalTab.route) {
    //   selection = this.renewalTab;
    // } else
    // if (!selection) {
    //   selection = this.menuTabs[0];
    // }
    // this.setPageName(selection.pageName);
  }
  setPageName(name) {
    this._pageName = name;
    this.selectedPage.next(name.toLowerCase());
    // if (name === this.riskDetailsTab.pageName) {
    //   this.isPDFDisable.next(true);
    // } else {
    this.isPDFDisable.next(false);
    // }
    // this.dashletDataSubject.next([]);
  }
  getPageName() {
    return this._pageName;
  }

  getDashletData(payloadConfig, pageName, queryTypeId = 1, isTotalRequired?, applyStaticFilters?, removedPayload?) {
    const rangeData = this.filterService.getDateRangeValues();
    let appliedFilters = this.filterService.getSelectedFilters();
    appliedFilters = this.filterService.getFYRate(appliedFilters);
    // let StaticFilters = applyStaticFilters ? true : false;

    if (!applyStaticFilters) {
      appliedFilters.appliedFilters.find(filter => {
        // Check if any of the producer related filters is applied 
        if (['ProducerGroupName', 'ProducerSubGroupName', 'ProducerRegion', 'ProducerCountry',
        'ProducerState'].indexOf(filter.key) > -1 || payloadConfig.dashlet == 'Producers') {
          if (payloadConfig.dashlet !== 'GWPExhibit' || ['IndustrySector', 'IndustryType'].indexOf(filter.key) < 0){
            applyStaticFilters = true;
          }
        }
      });
    }
    //Apply  Static Payload when we select any of the producer filters and also for Producer Location Map & Producer Office Map
    if (applyStaticFilters && removedPayload !== 'Producer') {
      let producerPayload = [
        { key: 'ClientIndustrySector', value: '"Insurance"', operator: 'IN' },
        {
          key: 'ClientIndustryType',
          value:
            '"Insurance","Insurance Broker","Insurance Intermediary","MGA","Property Program","Risk Management","Risk Retention Group"',
          operator: 'IN'
        }
      ];
      appliedFilters.appliedFilters = [...appliedFilters.appliedFilters, ...producerPayload];
    }


    if (removedPayload === "Insured") {
      //remove Insured Location Country, Insured Region Client Region , Client Country  and Producer State Filter For Producer Location Map
      removedPayload = appliedFilters.appliedFilters.filter(filterObj => {
        return !["InsuredCountry", "InsuredContinent", "ProducerState", "ClientRegion", "ClientCountry"].includes(filterObj.key);
      })
      appliedFilters.appliedFilters = [...removedPayload]
    } else if (removedPayload === "Producer") {
      //remove Producer Location Country, Producer Region  and Producer State Filters For Insured  Location Map
      removedPayload = appliedFilters.appliedFilters.filter(filterObj => {
        return !["ProducerRegion", "ProducerCountry", "ProducerState", "ClientRegion", "ClientCountry", "ClientIndustrySector", "ClientIndustryType"].includes(filterObj.key);
      })
      appliedFilters.appliedFilters = [...removedPayload]
    } else if (removedPayload === 'Client') {
      //remove Producer Location Country, Producer Region  and Producer State Filters For Client Location Map
      removedPayload = appliedFilters.appliedFilters.filter(filterObj => {
        return !["ProducerRegion", "ProducerCountry", "ProducerState", "InsuredCountry", "InsuredContinent", "ClientIndustrySector", "ClientIndustryType"].includes(filterObj.key);
      })
      appliedFilters.appliedFilters = [...removedPayload]
    } else if (removedPayload === "ProducerOffice") {
      removedPayload = appliedFilters.appliedFilters.filter(filterObj => {
        return !["InsuredCountry", "InsuredContinent", "ClientRegion", "ClientCountry"].includes(filterObj.key);
      })
      appliedFilters.appliedFilters = [...removedPayload]
    }
    // const dateRange = this.filterService.getSelectedFilterDateRange()
    if (appliedFilters['TimePeriod'] == 'CY') {
      appliedFilters['startDate'] = this.filterService.FYCYDates.startDate
      appliedFilters['endDate'] = this.filterService.FYCYDates.endDate
      if (appliedFilters['timePeriodValues'] && appliedFilters['timePeriodValues'].includes(moment().format('YYYY'))) {
        appliedFilters['timePeriodValues'] = appliedFilters['timePeriodValues'].split(',').filter(item => item != moment().format('YYYY')).join()
      }
    } else if (appliedFilters['TimePeriod'] == 'FY') {
      appliedFilters['startDate'] = this.filterService.FYCYDates.startDate
      appliedFilters['endDate'] = this.filterService.FYCYDates.endDate
      if (appliedFilters['timePeriodValues'] && appliedFilters['timePeriodValues'].includes(moment().add(1, 'years').format('YYYY'))) {
        appliedFilters['timePeriodValues'] = appliedFilters['timePeriodValues'].split(',').filter(item => item != moment().add(1, 'years').format('YYYY')).join();
      }
    }

    let callsList = [
      {
        userEmail: localStorage.getItem('userEmail'),
        userMDSId: localStorage.getItem('userId'),
        firstName: localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).firstName,
        lastName: localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).lastName,
        isExternal: 'false',
        dashboard: this._dashboardName,
        page: pageName ? pageName : this._pageName,
        queryTypeId,
        IsMDOrAbove: this.baseService.IsMDOrAbove,
        ...rangeData,
        ...payloadConfig,
        ...appliedFilters
      }
    ];

    if (isTotalRequired) {
      callsList.push({ ...callsList[0], queryTypeId: 5 });
    }
    this.globalPayload = callsList[0]
    callsList = callsList.map(call => this.apiService.post('dashlet', call));
    return forkJoin(callsList);
  }

  exportToExcelService(payloadConfig, fileName, queryTypeId = 1, exportType = 'excel') {
    const rangeData = this.filterService.getDateRangeValues();
    let appliedFilters = this.filterService.getSelectedFilters();
    appliedFilters = this.filterService.getFYRate(appliedFilters);
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      userMDSId: localStorage.getItem('userId'),
      firstName: localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).firstName,
      lastName: localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).lastName,
      isExternal: 'false',
      dashboard: this._dashboardName,
      page: this._pageName,
      toCurrency: 'GBP',
      IsMDOrAbove: this.baseService.IsMDOrAbove,
      queryTypeId,
      exportType,
      ...rangeData,
      ...payloadConfig,
      ...appliedFilters,
      timePeriodNextDays: this.filterService.nextDaysValue.value
    };
    if (payload.dashlet !== 'BusinessUnits' && payload.dashlet !== 'StrongerTogether' && payload.dashlet !== 'StrongerTogetherProducers') {
      delete payload['startDate'];
      delete payload['endDate'];
    }
    let applyStaticFilters;
    payload.appliedFilters.find(filter => {
      // Added Static Payload when we select any of the producer filters
      if (['ProducerGroupName', 'ProducerSubGroupName', 'ProducerRegion', 'ProducerCountry', 'IndustrySector', 'IndustryType', 'ProducerState'].indexOf(filter.key) > -1 || payload.dashlet == 'Producers') {
        if (payload.dashlet !== 'GWPExhibit' || ['IndustrySector', 'IndustryType', 'ProducerState'].indexOf(filter.key) < 0){
          applyStaticFilters = true;
        }
      }
    });
    if (applyStaticFilters) {
      let producerPayload = [
        { key: 'ClientIndustrySector', value: '"Insurance"', operator: 'IN' },
        {
          key: 'ClientIndustryType',
          value:
            '"Insurance","Insurance Broker","Insurance Intermediary","MGA","Property Program","Risk Management","Risk Retention Group"',
          operator: 'IN'
        }
      ];
      payload.appliedFilters = [...payload.appliedFilters, ...producerPayload];
    }
    return this.apiService.downloadFile('exportToExcel', fileName, payload);
  }

  exportToExcelGlobalService(dashletArr, fileName) {
    const rangeData = this.filterService.getDateRangeValues();
    let appliedFilters = this.filterService.getSelectedFilters();
    appliedFilters = this.filterService.getFYRate(appliedFilters);
    const dashlets = dashletArr.map(item => {
      // const dashletData = this.getSingleDashletData(item);
      const payload = {
        dashletName: item,
        sortColumnKey: '',
        sortDirection: 0,
        selectedEntity: ''
      };

      // if (dashletData.name === 'GWPbyYear' || dashletData.name === 'RRbyYear') {
      //   payload['selectedEntity'] = dashletData.selectedEntity;
      // }
      return payload;
    });
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      userMDSId: localStorage.getItem('userId'),
      firstName: localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).firstName,
      lastName: localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).lastName,
      isExternal: 'false',
      dashboard: this._dashboardName,
      page: this._pageName,
      queryTypeId: 3,
      startDate: rangeData.startDate,
      endDate: rangeData.endDate,
      pageIndex: 1,
      pageSize: 15,
      toCurrency: 'GBP',
      exportType: 'excel',
      IsMDOrAbove: this.baseService.IsMDOrAbove,
      dashlets,
      ...appliedFilters
    };

    let applyStaticFilters;
    payload.appliedFilters.find(filter => {
      // Added Static Payload when we select any of the producer filters
      if (['ProducerGroupName', 'ProducerSubGroupName', 'ProducerRegion', 'ProducerCountry', 'ProducerState'].indexOf(filter.key) > -1 || payload.dashlet == 'Producers') {
        if (payload.dashlet !== 'GWPExhibit' || ['IndustrySector', 'IndustryType'].indexOf(filter.key) < 0){
          applyStaticFilters = true;
        }
      }
    });
    if (applyStaticFilters) {
      let producerPayload = [
        { key: 'ClientIndustrySector', value: '"Insurance"', operator: 'IN' },
        {
          key: 'ClientIndustryType',
          value:
            '"Insurance","Insurance Broker","Insurance Intermediary","MGA","Property Program","Risk Management","Risk Retention Group"',
          operator: 'IN'
        }
      ];
      payload.appliedFilters = [...payload.appliedFilters, ...producerPayload];
    }


    return this.apiService.downloadFile('exportGlobalExcel', fileName, payload);
  }



  databaseExportService(dashletArr, fileName, sendEmail) {
    const rangeData = this.filterService.getDateRangeValues();
    let appliedFilters = this.filterService.getSelectedFilters();
    appliedFilters = this.filterService.getFYRate(appliedFilters);
    const dashlets = dashletArr.map(item => {
      const dashletData = this.getSingleDashletData(item);
      const payload = {
        dashletName: item,
        sortColumnKey: '',
        sortDirection: 0,
        selectedEntity: dashletData.selectedEntity
      };

      if (dashletData.name === 'GWPByYear' || dashletData.name === 'GWPExhibit') {
        payload['selectedEntity'] = '';
      }
      return payload;
    });
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      userMDSId: localStorage.getItem('userId'),
      firstName: localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).firstName,
      lastName: localStorage.getItem('userProfile') && JSON.parse(localStorage.getItem('userProfile')).lastName,
      isExternal: 'false',
      dashboard: this._dashboardName,
      page: this._pageName,
      queryTypeId: 3,
      startDate: rangeData.startDate,
      endDate: rangeData.endDate,
      pageIndex: 1,
      pageSize: 15,
      toCurrency: 'GBP',
      exportType: 'excel',
      IsMDOrAbove: this.baseService.IsMDOrAbove,
      dashlets,
      ...appliedFilters,
      fileName: 'Global Export'
    };
    let applyStaticFilters;
    payload.appliedFilters.find(filter => {
      // Added Static Payload when we select any of the producer filters
      if (['ProducerGroupName', 'ProducerSubGroupName', 'ProducerRegion', 'ProducerCountry', 'ProducerState'].indexOf(filter.key) > -1 || payload.dashlet == 'Producers') {
        if (payload.dashlet !== 'GWPExhibit' || ['IndustrySector', 'IndustryType'].indexOf(filter.key) < 0){
          applyStaticFilters = true;
        }
      }
    });
    if (applyStaticFilters) {
      let producerPayload = [
        { key: 'ClientIndustrySector', value: '"Insurance"', operator: 'IN' },
        {
          key: 'ClientIndustryType',
          value:
            '"Insurance","Insurance Broker","Insurance Intermediary","MGA","Property Program","Risk Management","Risk Retention Group"',
          operator: 'IN'
        }
      ];
      payload.appliedFilters = [...payload.appliedFilters, ...producerPayload];
    }

    //SOP and Ranking dashlets Payload if any of the producerGroup/SubGroup or Market/SubMarket filter is applied
    let marketOrProducer = payload.appliedFilters.filter(filter => {
      return ['ProducerGroupName', 'ProducerSubGroupName', 'PlacingMarket', 'PlacingSubMarket'].indexOf(filter.key) > -1;
    });

    if (marketOrProducer.length) {
      payload.dashlets = [...payload.dashlets,
      ...[{ dashletName: "MSWExhibit", sortColumnKey: "", sortDirection: 0, selectedEntity: "" },
      { dashletName: "RankExhibit", sortColumnKey: "", sortDirection: 0, selectedEntity: "" }]]
    }
    return this.apiService.post('emailGlobalExcel', payload);
  }

  mockExportToExcelService(fileName, sasUri) {
    const payload = {
      fileName,
      sasUri,
      UserName: localStorage.getItem('userEmail')
    };
    return this.apiService.downloadFile('exportDatabaseExcelChunks', fileName, {}, payload);
  }


  exportFullScreenService(dashlet, fileName, exportType) {
    const rangeData = this.filterService.getDateRangeValues();
    let appliedFilters = this.filterService.getSelectedFilters();
    appliedFilters = this.filterService.getFYRate(appliedFilters);
    const dashletData = this.getSingleDashletData(dashlet);
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      dashboard: this._dashboardName,
      page: this._pageName,
      dashlet,
      queryTypeId: 3,
      startDate: rangeData.startDate,
      endDate: rangeData.endDate,
      pageIndex: 0,
      pageSize: 0,
      toCurrency: 'GBP',
      exportType,
      IsMDOrAbove: this.baseService.IsMDOrAbove,
      selectedEntity: dashletData.selectedEntity,
      sortColumnKey: dashletData.sortColumnName,
      sortDirection: dashletData.sortOrder,
      ...appliedFilters,
      timePeriodNextDays: this.filterService.nextDaysValue.value
    };
    return this.apiService.downloadFile('exportToExcel', fileName, payload);
  }

  getFooterSummaryData() {
    let appliedFilters = this.filterService.getSelectedFilters();
    appliedFilters = this.filterService.getFYRate(appliedFilters);
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      dashboard: this._dashboardName,
      toCurrency: 'GBP',
      ...appliedFilters
    };
    return this.apiService.post('footerInfo', payload);
  }

  /* Refactored code for dashlet export logic */

  // Register the dashlet when it is initialised
  setDashletActivation(name, title, type, selectedEntity?) {
    this._activeDashletTypes[name] = {
      name,
      title,
      type,
      chartInstance: null,
      tableData: null,
      selectedEntity
    };
  }
  // Update the type of dashlet when we toggle
  updateDashletType(name, type) {
    this._activeDashletTypes[name].type = type;
    this.dataFormat.next(type)
  }
  // Update the dashlet data when dashlet data updated
  updateDashletTableData(name, data, sortData, selectedEntity = '') {
    this._activeDashletTypes[name].tableData = data;
    this._activeDashletTypes[name] = { ...this._activeDashletTypes[name], ...sortData, selectedEntity };
  }
  // Update the chart instancce when chart instance updated
  updateDashletChartData(name, data) {
    this._activeDashletTypes[name].chartInstance = data;
  }
  // Deactivate the dashlet from store when dashlet destroyed
  setDashletDeactivation(name) {
    delete this._activeDashletTypes[name];
  }
  // Get names of all the active dashlets
  getDashletsNames() {
    return Object.keys(this._activeDashletTypes);
  }
  // Get the info of the dashlet type
  getSingleDashletData(type) {
    return { ...this._activeDashletTypes[type] };
  }

  // Create the pdf table from the displayed table data
  createPDFTableData(tableData) {
    const tableHead = [];
    tableHead.push(tableData.Headers.map(item => item.headerName));

    const tableKeys = tableData.Headers.map(item => item.field);
    const tableBody = tableData.Data.map(data => tableKeys.map(key => this.getFormattedPDFTableValue(data[key])));

    let totalData = Object.values(tableData.TotalData);
    totalData.shift();
    // formatting total data
    totalData = totalData.map(item => this.getFormattedPDFTableValue(item));
    tableBody.push(totalData);

    return { head: tableHead, body: tableBody };
  }

  // Format the number of the table
  getFormattedPDFTableValue(value, decimal = 0) {
    if (value == null) {
      return 'N/A';
    } else if (typeof value !== 'number') {
      return value;
    }
    const formatted = Math.abs(value)
      .toFixed(decimal)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value < 0 ? `(${formatted})` : formatted;
  }

  // Get all dashlets pdf data
  getAllDashletPDFData(dashlets) {
    const allDashletsData = dashlets.map(dashlet => {
      const dashletData = this.getSingleDashletData(dashlet);
      if (dashletData.type === 'table') {
        dashletData.tableData = this.createPDFTableData(dashletData.tableData);
      }
      return dashletData;
    });
    return allDashletsData;
  }

  // Get the current filters table data
  createPDFFiltersTable() {
    const appliedFilters = this.filterService.getFiltersForPDF();
    return {
      head: [['Filter Name', 'Filter Value']],
      body: Object.entries(appliedFilters)
    };
  }

  getExhibitGwpValue(value) {
    this.exhibitGwpValue.next(value);
    return value;
  }

  getFirstYearGwpValue(value) {
    this.firstYearGwpValue.next(value);
    return value;
  }

  getSecondYearGwpValue(value) {
    this.secondYearGwpValue.next(value);
    return value;
  }

  getThirdYearGwpValue(value) {
    this.thirdYearGwpValue.next(value);
    return value;
  }

  getFourthYearGwpValue(value) {
    this.fourthYearGwpValue.next(value);
    return value;
  }

  getFifthYearGwpValue(value) {
    this.fifthYearGwpValue.next(value);
    return value;
  }
  /* Refactored code for dashlet export logic */

  // Old code for export

  // getChartInstance() {
  //   return this.chartInstanceSubject.value;
  // }

  // setChartInstance(chartInstance) {
  //   this.chartInstanceSubject.next(chartInstance);
  // }

  // getPdfData() {
  //   return this.dashletDataSubject.value;
  // }

  // updatePdfData(data) {
  //   this.dashletDataSubject.next(data);
  // }

  // setPdfData(data) {
  //   const currentValue = this.dashletDataSubject.value;
  //   const updatedValue = [...currentValue, data];
  //   this.dashletDataSubject.next(updatedValue);
  // }

  // resetPdfData() {
  //   this.dashletDataSubject.next([]);
  // }
}
