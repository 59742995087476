export const levelsConfigs = [
    {name:'PlacingMarket', index: 0, item: 'Placing Markets', dashlet: 'Placing Markets'},
    {name:'PlacingSubMarket', index: 1, item: 'Placing Sub Markets', dashlet: 'Placing Markets'},
    {name:'ProducerGroupName', index: 0, item: 'Producer Name', dashlet: 'Producers'},
    {name:'ProducerSubGroupName', index: 1, item: 'Sub Producer Name', dashlet: 'Producers'},
    {name:'CapacityMarket', index: 0, item: 'Capacity Markets', dashlet: 'Capacity Markets'},
    {name:'CapacitySubMarket', index: 1, item: 'Capacity Sub Markets', dashlet: 'Capacity Markets'},
    {name:'IndustrySector', index: 0, item: 'Insured Industry Sector', dashlet: 'Industry'},
    {name:'Product', index: 0, item: 'Products', dashlet: 'Products'},
    {name:'Region', index: 0, item: 'Pillar', dashlet: 'Howden Business Units'},
    {name:'Company', index: 1, item: 'Division', dashlet: 'Howden Business Units'},
    {name:'Division', index: 2, item: 'Level 1', dashlet: 'Howden Business Units'},
    {name:'Team', index: 3, item: 'Level 2', dashlet: 'Howden Business Units'},
    {name:'SubTeam', index: 4, item: 'Level 3', dashlet: 'Howden Business Units'},
    {name:'DisplayLevel1', index: 0, item: 'Level 1', dashlet: 'Stronger Together Producers'},
    {name:'DisplayLevel2', index: 1, item: 'Level 2', dashlet: 'Stronger Together Producers'},
    {name:'DisplayLevel3', index: 2, item: 'Level 3', dashlet: 'Stronger Together Producers'},
    {name:'PolicyRegion', index: 0, item: 'Pillar', dashlet: 'Stronger Together'},
    {name:'PolicyCompany', index: 1, item: 'Division', dashlet: 'Stronger Together'},
    {name:'PolicyDivision', index: 2, item: 'Level 1', dashlet: 'Stronger Together'},
    {name:'PolicyTeam', index: 3, item: 'Level 2', dashlet: 'Stronger Together'},
    {name:'PolicySubTeam', index: 4, item: 'Level 3', dashlet: 'Stronger Together'}
];

export const drillUpsLevelsConfigs = [
    {index: 0, item: 'Placing Markets', name: 'PlacingMarket', displayName: 'Placing Market', dashletName: 'Placing Markets'},
    {index: 1, item: 'Placing Sub Markets', name: 'PlacingSubMarket', displayName: 'Placing Sub-market', dashletName: 'Placing Markets'},
    {index: 0, item: 'Producer Name', name: 'ProducerGroupName', displayName: 'Producer Group', dashletName: 'Producers'},
    {index: 1, item: 'Sub Producer Name', name: 'ProducerSubGroupName', displayName: 'Producer Sub Group', dashletName: 'Producers'},
    {index: 0, item: 'Insured Industry Sector', name: 'IndustrySector', displayName: 'Industry Sector', dashletName: 'Industry'},
    {index: 1, item: 'Insured Industry Sector', name: 'IndustrySector', displayName: 'Industry Sector', dashletName: 'Industry'},
    {index: 0, item: 'Capacity Markets', name: 'CapacityMarket', displayName: 'Capacity Market', dashletName: 'Capacity Markets'},
    {index: 1, item: 'Capacity Sub Markets', name: 'CapacitySubMarket', displayName: 'Capacity Sub Market', dashletName: 'Capacity Markets'},
    {index: 0, item: 'Products', name: 'Product', displayName: 'Product', dashletName: 'Products'},
    {index: 0, item: 'Pillar', name: 'Region', displayName: 'Howden Pillar', dashletName: 'Howden Business Units'},
    {index: 1, item: 'Division', name: 'Company', displayName: 'Howden Division', dashletName: 'Howden Business Units'},
    {index: 2, item: 'Level 1', name: 'Division', displayName: 'Howden Business Unit Level 1', dashletName: 'Howden Business Units'},
    {index: 3, item: 'Level 2', name: 'Team', displayName: 'Howden Business Unit Level 2', dashletName: 'Howden Business Units'},
    {index: 4, item: 'Level 3', name: 'SubTeam', displayName: 'Howden Business Unit Level 3', dashletName: 'Howden Business Units'},
    {index: 0, item: 'Pillar', name: 'PolicyRegion', displayName: 'Recipients Pillar', dashletName: 'Stronger Together'},
    {index: 1, item: 'Division', name: 'PolicyCompany', displayName: 'Recipients Division', dashletName: 'Stronger Together'},
    {index: 2, item: 'Level 1', name: 'PolicyDivision', displayName: 'Recipients Business Unit Level 1', dashletName: 'Stronger Together'},
    {index: 3, item: 'Level 2', name: 'PolicyTeam', displayName: 'Recipients Howden Business Unit Level 2', dashletName: 'Stronger Together'},
    {index: 4, item: 'Level 3', name: 'PolicySubTeam', displayName: 'Recipients Howden Business Unit Level 3', dashletName: 'Stronger Together'},
    {index: 0, item: 'Level 1', name: 'DisplayLevel1', displayName: 'Level 1', dashletName: 'Stronger Together Producers'},
    {index: 1, item: 'Level 2', name: 'DisplayLevel2', displayName: 'Level 2', dashletName: 'Stronger Together Producers'},
    {index: 2, item: 'Level 3', name: 'DisplayLevel3', displayName: 'Level 3', dashletName: 'Stronger Together Producers'}
];