
    <div class="access-denied-message-container">
        <div class="logo">
          <img src="../../../assets/Hyperion_X/Howden-rebranding-white.svg" alt="">
        </div>
        <div>
          <h1 *ngIf="downloadStatus === 'INPROGRESS'">Download in progress</h1>
          <h1 *ngIf="downloadStatus === 'COMPLETED'">Excel downloaded</h1>
          <h1 *ngIf="downloadStatus === 'ERROR'">
            The Link has expired. <br>
            Please generate a new export file.
          </h1>
        </div>
        <div class="logo-acc">
          <img src="../../../assets/accelerator-logo.png" alt="">
        </div>
      </div>
  
    