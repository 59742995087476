import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN_PARTICLE_PARAMS } from 'src/app/shared/constants/common-constants';
import { MsalService } from '@azure/msal-angular';
declare var particlesJS: any;

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent implements OnInit {
  constructor(private router: Router,
    private authService: MsalService) { }

  ngOnInit() {
    particlesJS('particles-js', LOGIN_PARTICLE_PARAMS);
  }

  async logout() {
    await this.authService.logoutRedirect();
    this.router.navigate(['/login']);
  }
}
