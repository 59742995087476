<div id="particles-js">
  <div class="access-denied-message-container">
    <div class="logo">
      <img src="../../../assets/Hyperion_x/HX-logo-png.png" class="unauthorized-logo" alt="">
    </div>
    <div>
      <h1>Access Denied </h1>
      <p>It seems you do not have the necessary <br>
        permissions to access this dashboard. </p>
        
        <p>To gain access please contact:<br>
          support@hyperion-x.com </p>

          <div class="btn-wrapper d-flex justify-content-center pt-4">
            <button (click)="logout()">LOG OUT</button>
          </div>
    </div>
    <div class="logo-acc">
      <img src="../../../assets/accelerator-logo.png" alt="">
    </div>
  </div>
</div>
