export class AccessLevel {
  levels: Array<NestedItem>;
  constructor(obj) {
    this.levels = obj.map(i => new NestedItem(i));
  }
}

class NestedItem {
  name: string;
  type: string;
  data: Array<NestedItem>;
  constructor(nestedObj) {
    this.name = nestedObj.Name;
    this.type = nestedObj.Type;
    this.data = nestedObj.Data ? nestedObj.Data.map(i => new NestedItem(i)) : null;
  }
}
