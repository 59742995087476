import { Injectable } from '@angular/core';
import { NegativeValuePipe } from '../../pipes/negative-value.pipe';
import * as moment from 'moment';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  constructor(private negativeValuePipe: NegativeValuePipe) { }

  numberKeys = ['Risk Reference', 'Retained Revenue(GBP)', 'Retained Revenue(Orig Ccy)', 'Retained Revenue(Sett Ccy)'];

  // Function to create dashlet table (without pagination)
  createTableData(tab, data, showMoreRecords = false, isRenewalPdf = false) {
    const keys = tab.headersList.filter(i => i.isPresentInDashlet);
    const dashletTableData = this.initTableData(data);
    dashletTableData.Headers = keys.map(key => this.createColumnHeaderConfig(data ? data[0] : {}, key, 12));
    // Commenting below line as total for dashlet is not required now
    if (showMoreRecords) {
      dashletTableData.TotalData = this.addTableTotalData(data, keys);
    }
    if (isRenewalPdf) {
      dashletTableData.Data = data[0];
      dashletTableData.TotalData = this.addTableGrandTotal(data[1], keys);
    }
    return dashletTableData;
  }

  // Function to create table with pagination
  createPaginatedTableData(tab, pageSize, data, valueLength = 12, totalData?) {
    const keys = tab.headersList ? tab.headersList.filter(i => !i.keyNotPresentOnFullView) : tab;
    const pages = Math.ceil(data.TotalRecords / pageSize);
    const fullScreenDashletData = this.initTableData(data.Data, pages);
    fullScreenDashletData.Headers = keys.map(key => this.createColumnHeaderConfig(data.Data[0], key, valueLength, "fullView"));
    if (totalData) {
      fullScreenDashletData.TotalData = this.addTableGrandTotal(totalData, keys);
    }
    return fullScreenDashletData;
  }

  // Function to initialise the table data
  initTableData(data, pages = 1) {
    return {
      Data: data,
      Headers: [],
      totalPages: pages,
      TotalData: {
        cellClass: 'total-table-cell'
      }
    };
  }

  // Function to calculate dashlet total from the records
  addTableTotalData(data, headersInfo) {
    const totalData = {
      cellClass: 'total-table-cell'
    };
    for (let i = 0; i < headersInfo.length; i++) {
      let value = '';
      const header = headersInfo[i];
      const key = header.key;
      if (i === 0) {
        value = 'Total';
      } else if (header.isTotal && data && data[0] && typeof data[0][key] === 'number') {
        value = data.reduce((a, b) => a + b[key], 0);
      }
      totalData[key] = value;
    }
    return totalData;
  }

  // Function to add grand total to the table
  addTableGrandTotal(data, headersInfo) {
    const totalData = {
      cellClass: 'total-table-cell'
    };
    for (let i = 0; i < headersInfo.length; i++) {
      const value = '';
      const header = headersInfo[i];
      const key = header.key ? header.key : header.field;
      if (i === 0) {
        totalData[key] = 'Grand Total';
      } else if (!data[0][key] || !header.isTotal) {
        totalData[key] = '';
      } else {
        totalData[key] = data[0][key];
      }
    }
    return totalData;
  }

  createColumnHeaderConfig(data, headerInfo, valueLength, view?) {
    if (!data) {
      return;
    }
    const type = typeof data[headerInfo.key];
    let cellClass = type === 'number' ? `table-cell values` : 'table-cell';

    // if(headerInfo.displayKey==="Renewal Status" && headerInfo.key==="BusinessStatus"){
    //   cellClass = "table-cell renValue"
    // }
    // adding class for null values
    if (data[headerInfo.key] == null && headerInfo.isNumberKey) {
      cellClass += ' null-value';
    }

    // Need to remove
    // Condition for number columns in risk details
    if (this.numberKeys.indexOf(headerInfo.key) > -1) {
      cellClass += ' values';
    }

    const headerParams = {
      enableSort: false,
      field: headerInfo.key || headerInfo.field
    };

    const cellDefData = {
      headerName: headerInfo.displayKey,
      field: headerInfo.key,
      cellClass,
      cellRenderer: params => this.cellRendererForColumn(headerInfo, params, valueLength, view),
      // tooltipField: headerInfo.displayKey,
      headerComponentParams: {
        ...headerParams,
        cellClass
      },
      headerTooltip: headerInfo.tooltipDisplayKey ? headerInfo.tooltipDisplayKey : headerInfo.displayKey,
      lockPosition: true
    };
    if (!headerInfo.isTotal) {
      cellDefData['cellClass'] += ' number-cell';
    }
    if (headerInfo.width) {
      cellDefData['width'] = headerInfo.width;
    }
    // Condition when we have column defs created earlier (for ex as in risk details)
    if (headerInfo.headerName) {
      headerInfo.cellRenderer = cellDefData.cellRenderer;
      return { ...headerInfo };
    }
    return cellDefData;
  }

  displayToolTip(value) {
    if (value == null) {
      return 'N/A';
    } else if (typeof value === 'number') {
      return value;
    } else {
      return value.replace(/[ ]/g, '\u00a0');
    }
  }

  getTooltipFormattedValue(value) {
    const tooltipValue = value.toFixed(2);
    return tooltipValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  cellRendererForColumn(headerInfo, params, valueLength, view?) {
    const key = headerInfo.displayKey;
    const title = this.displayToolTip(params.value);
    const decimal = headerInfo.hasTrendsArrow ? 2 : 1;
    const formattedValue = this.getFormattedValue(params.value, decimal, headerInfo, valueLength, view);
    if (headerInfo.hasTrendsArrow && typeof params.value === 'number') {
      return this.addArrowRenderer(title, formattedValue, params.value < 0);
    } else if (headerInfo.hasPercentageValue && typeof params.value === 'number') {
      return this.percentageRenderer(title, formattedValue, params.value < 0);
    } else if (headerInfo.hasStatusColor) {
      return this.addStatusColorRenderer(title, formattedValue, params['data']);
    } else {
      let tooltipValue;
      if (typeof title === 'number') {
        tooltipValue = this.getTooltipFormattedValue(title);
      } else {
        tooltipValue = title;
      }
      if (params && params.data && params.data.IsTotals) {
        return `<span title=${tooltipValue} style="font-weight:bold">${formattedValue}</span>`;
      } else {
        return `<span title=${tooltipValue}>${formattedValue}</span>`;
      }
    }
  }

  getFormattedValue(value, decimal = 0, headerInfo, valueLength, view?) {
    if (value == null) {
      return 'N/A';
    } else if (headerInfo.isDateType && typeof value !== 'number') {
      return value;
    } else if (typeof value !== 'number') {
      return value;
      // return value.length > valueLength
      // ? value.substr(0, valueLength - 6) + '...' + value.substr(value.length - 3, value.length) : value;
    }
    const formatted = headerInfo.disableMillionConversion ? Math.abs(value).toFixed(decimal) : Math.abs(value / 1000000).toFixed(decimal);
    // .toString()
    // .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (value < 0) {
      return view === "fullView" ? `(${formatted})` : `(${formatted} m)`;
    }
    return view === "fullView" ? `${formatted}` : `${formatted} m`;
  }

  formatPercentageDecimal(value) {
    return this.negativeValuePipe.transform(value, '', '', '', 2) + '%';
  }

  formatPercent(value, negative) {
    const tableValue = this.negativeValuePipe.transform(value, '', '', '', 0);
    return tableValue + '%';
  }

  addArrowRenderer(title, formattedValue, isNegative) {
    const value = this.formatPercent(title, isNegative);
    const newValue = `<div class="variance-container" title=` + this.formatPercentageDecimal(title) + `>${value}</div>`;
    if (!isNegative) {
      return `${newValue}<i class="fas fa-arrow-up" style="font-size: 12px;color: green;padding-left: 5px;"></i>`;
    } else {
      return `${newValue}<i class="fas fa-arrow-down" style="font-size: 12px;color: red;padding-left: 5px;"></i>`;
    }
  }

  addStatusColorRenderer(title, formattedValue, data) {
    if (data && data['LastUpdatedColourCode'] === 'Red') {
      return `<div class="variance-container text-red" title="${title}">${formattedValue}</div>`;
    } else {
      return `<div class="variance-container" title="${title}">${formattedValue}</div>`;
    }
  }

  percentageRenderer(title, formatted, isNegative) {
    const value = this.negativeValuePipe.transform(title, '%');
    return `<div class="variance-container" title=` + this.formatPercentageDecimal(title) + `>${value}</div>`;
  }
}
