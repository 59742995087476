import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { BaseService } from '../services/base/base.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private currentRoute: ActivatedRoute) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // If the user is authenticated, then continue
    if (await this.authService.checkSessionAuth()) {
      return true;
    }
    // if()

    // Else redirect to login with return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  }

  async canLoad(route: Route) {
    // if (await this.authService.checkSessionAuth()) {
    //   return true;
    // }
    const returnUrl = this.currentRoute.snapshot.queryParams['returnUrl'];
    this.router.navigate(['/login'], { queryParams: { returnUrl } });
  }
}
