import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashletService } from 'src/app/shared/services/dashlet/dashlet.service';

@Component({
  selector: 'app-export-excel',
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.scss']
})
export class ExportExcelComponent implements OnInit {

  downloadStatus: 'INPROGRESS' | 'COMPLETED' | 'ERROR' = 'INPROGRESS';
  fileName;
  sasUri
  constructor(private dashletService: DashletService, private route: ActivatedRoute, private router: Router) {
    this.route.queryParamMap.subscribe(params => {
      this.fileName = params.get('fileName')
      this.sasUri = params.get('sasUri')
    });
  }

  ngOnInit(): void {
    this.dashletService.mockExportToExcelService(this.fileName, this.sasUri).subscribe(
      res => {
        this.downloadStatus = 'COMPLETED';
      },
      err => {
        this.downloadStatus = 'ERROR';
      }
    );
  }

}
