import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { DASHBOARD_NAME } from '../../constants/common-constants';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessLevel } from '../../models/access-level.model';
import { GoogleAnalytics } from '@JFrogPackage/analytics-lib';
import { AppConfig } from 'src/app/app.config';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor(private apiService: ApiService, private appConfig: AppConfig, private router: Router) { }
  public lastLoggedInTime: BehaviorSubject<any> = new BehaviorSubject(null);
  public isMDOrAboveSub: BehaviorSubject<any> = new BehaviorSubject(null);
  public isDefaultPageView: BehaviorSubject<any> = new BehaviorSubject(null);
  public isLoginSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public matTabChange: BehaviorSubject<boolean> = new BehaviorSubject(null);
  IsMDOrAbove;



  reportAnIssue(formData) {
    const payload = {
      dashboard: DASHBOARD_NAME,
      userEmail: localStorage.userEmail,
      ...formData
    };
    return this.apiService.post('reportIssue', payload);
  }

  async setLoggedInTime(updateLastLogin) {
    return new Promise<void>((resolve, reject) => {
      const profile = JSON.parse(localStorage.getItem('userProfile'));
      const payload = {
        firstName: profile.firstName,
        lastName: profile.lastName ? profile.lastName : '""',
        middleName: '""',
        emailID: profile.email,
        loginID: profile.login,
        updateLastLogin
      };
      this.apiService.get('loginTime', payload).subscribe(
        data => {
          this.setLastLoggedInTime(data);
          resolve();
        },
        err => {
          console.log('error getting time', err);
          resolve();
        }
      );
    });
  }

  private setLastLoggedInTime(dateTimeData) {
    const datetime = new Date(dateTimeData);
    const date = datetime.toLocaleDateString('en-GB');
    const time = datetime.toLocaleTimeString('en-GB');
    const newFormat = `${date} ${time}`;
    this.lastLoggedInTime.next(newFormat);
  }

  getBUAccessLevelInfo() {
    const payload = {
      email: localStorage.getItem('userEmail'),
      dashboard: DASHBOARD_NAME,
      page: 'UserBUAccessLevel',
      dashlet: 'UserBUAccessLevel',
      dashletTypeId: 1
    };
    return this.apiService.get('accessLevel', payload).pipe(
      map(data => {
        if (!data) {
          this.IsMDOrAbove = false;
        } else {
          this.IsMDOrAbove = data['IsMDOrAbove'];
        }
        this.isMDOrAboveSub.next(this.IsMDOrAbove);
        return new AccessLevel(data.Data);
      }),
      map(data => data.levels)
    );
  }

  submitFeedback(rating, comments) {
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      rating,
      comments
    };
    return this.apiService.post('submitFeedback', payload);
  }
  parseToBoolean(value) {
    if (value) {
      return value.toString().toLowerCase() === 'true';
    }
    return false;
  }

  getUserMDSDetails() {
    const profile = JSON.parse(localStorage.getItem('userProfile'));
    const payload = {
      email: profile.email,
      page: 'UserMDSInformation',
      dashlet: 'UserMDSInformation',
      dashletTypeId: 1
    };

    this.apiService.get('userMDSDetails', payload).subscribe(data => {
      if (data) {
        localStorage.setItem('userId', data);
        if (this.parseToBoolean(this.appConfig.googleAnalytics.trackingOn)) {
          if (!this.isDefaultPageView.getValue()) {
            GoogleAnalytics.addCustomGAEvents('page_view', {
              page_title: this.router.url,
              page_location: this.router.url,
              userId: data,
              pageName: this.router.url
            });
          }
        }
      }
    });
  }
}
