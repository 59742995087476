import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor() {}

  addGoogleAnalytics(trackingCode) {
    // Global site tag (gtag.js) - Google Analytics
    const analyticsScript = document.createElement('script');
    analyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingCode;
    document.head.appendChild(analyticsScript);

    const script = document.createElement('script');
    script.innerHTML = `//const profile = JSON.parse(localStorage.getItem('userProfile'));
      //const customUserId = profile.firstName + profile.lastName;
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingCode}');`;
    document.head.appendChild(script);
  }

  addHotJar(trackingCode) {
    const hotJarScript = document.createElement('script');
    hotJarScript.innerHTML = `(function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:'${trackingCode}',hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    document.head.appendChild(hotJarScript);

    // Install this snippet AFTER the Hotjar tracking code.
    // const userDataScript = document.createElement('script');
    // userDataScript.innerHTML =
    // `var userId = 'Test user' || null; // Replace your_user_id with your own if available.
    // window.hj('identify', userId, {
    // Role: 'Admin'
    // // Add your own custom attributes here. Some EXAMPLES:
    // // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
    // // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
    // // 'Total purchases': 15, // Send numbers without quotes.
    // // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
    // // 'Last refund date': null, // Send null when no value exists for a user.
    // });`;
    // document.head.appendChild(userDataScript);
  }
}
