import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UnauthorisedComponent } from './pages/unauthorised/unauthorised.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './shared/guards/auth.gaurd';
import { AppInsightsService } from './shared/services/app-insights.service';
import { NegativeValuePipe } from './shared/pipes/negative-value.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { AuthConfig } from 'src/assets/auth-config';
import { ExportExcelComponent } from './pages/export-excel/export-excel.component';
import { ClientLocationMapComponent } from './pages/producers/client-location-map/client-location-map.component';
import { StartAndEndDatePipe } from './shared/pipes/start-and-end-date.pipe';

var authConfig;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(authConfig.msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(authConfig.protectedResources.api.endpoint, authConfig.protectedResources.api.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: authConfig.loginRequest
  };
}

@NgModule({
  declarations: [AppComponent, UnauthorisedComponent, ExportExcelComponent],
  imports: [BrowserModule, HttpClientModule, AppRoutingModule, BrowserAnimationsModule, MatSnackBarModule, MatDialogModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true }
    },
    MsalService,
    MsalGuard,
    AuthGuard,
    MsalBroadcastService,
    AppInsightsService,
    AuthConfig,
    NegativeValuePipe,
    StartAndEndDatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private authConfiguration: AuthConfig) {
    authConfig = authConfiguration;
  }
}
