<div class="main-container">
    <div class="content-container">
        <div class="content">
            <div>
                <img class="logo" src="/assets/Hyperion_x/Howden-rebranding.svg" alt="Full Screen" />
            </div>
            <div class="body">
                <h1 class="mb-3">Howden Markets Terms and Conditions </h1>
                <ol class="list-items">
                    <li class="list-item">Introduction
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>These terms and conditions (“Site Terms”) and the terms of the
                                    master agreement between the parties (“Master Agreement”) (together the "Agreement")
                                    set
                                    out the basis on which Howden Group Holdings Limited (“HGHL”) agrees to allow you (the “Insurer”)
                                    the
                                    ability to view your data within the broking group through a range of infographics.
                                    The
                                    information presented is, inter alia, gross written premium split by various
                                    categories
                                    including relevant business units, products, upcoming renewals (together the
                                    "Services")
                                    through the https://howden-markets.com/ web site (the "Site").</p>
                            </li>
                            <li class="inner-list-item">
                                <p>By using the Site the Insurer is deemed to have accepted the
                                    Agreement in its entirety and agrees to use the Site solely in accordance with this
                                    Agreement.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>The site is owned and operated by HGHL.</p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Registration and Rights of Utilisation of the Site
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>The Insurer will provide HGHL with such personal information as
                                    HGHL may reasonably request relating to those of the Insurer's employees whom HGHL
                                    has
                                    approved in writing will be permitted to access and use the Site. Following receipt
                                    of
                                    such details, HGHL shall provide the Insurer with details of its usernames for use
                                    via
                                    the Site.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>Following receipt of its usernames and passwords in accordance
                                    with clause 2.1 above, the Insurer may access and use the services provided on the
                                    Site.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>Use of the Site is provided to the Insurer in accordance with
                                    the charges set out in the Master Agreement.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>HGHL reserves the right, at any time and without prior notice or
                                    reason, to reject, suspend or cancel the registration of an Insurer, and its access,
                                    to
                                    the Site and terminate this Agreement. In the event that such rejection, suspension,
                                    cancellation or termination occurs, HGHL shall not be liable to the Insurer in any
                                    way
                                    whatsoever for such rejection, suspension, cancellation or termination.</p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">User IDs and Passwords
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>The Insurer is solely responsible in all respects for all use of
                                    and for protecting the confidentiality of all usernames and passwords which may be
                                    given
                                    to the Insurer by HGHL or selected by the Insurer for use via the Site. The Insurer
                                    is
                                    fully responsible for all activities which occur under such usernames and passwords.
                                    The
                                    Insurer may not share these with or transfer them to any third parties. The Insurer
                                    must
                                    immediately notify HGHL of any unauthorised use of them or any other breach of
                                    security
                                    regarding the Site.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>When an individual employee of the Insurer leaves its employment
                                    or is otherwise no longer to be granted access to the Site, the Insurer shall notify
                                    HGHL immediately in writing so that HGHL can disable and/or re-assign the
                                    corresponding
                                    username and password.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>The Insurer will indemnify HGHL for any loss or damage resulting
                                    from misuse of the assigned or chosen username or password.</p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Disclaimers
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>HGHL is providing the Site on an "as is" and "as available"
                                    basis and makes no representations or warranties of any kind with respect to the
                                    Site or
                                    the content contained in the Site (including any text, graphics, advertisements,
                                    links
                                    or other items) and disclaims all such representations and warranties. In addition,
                                    HGHL
                                    does not warrant that use of the Site will be free from viruses, uninterrupted,
                                    error-free or secure. Use of the Site and the Services are entirely at the Insurer’s
                                    own
                                    risk.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>HGHL makes no representation nor gives any warranty, condition,
                                    undertaking or term either express or implied as to the condition, quality,
                                    performance,
                                    suitability, fitness for purpose, or completeness of the content contained on the
                                    Site
                                    or that such content will be accurate and up to date.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>The Insurer acknowledges that it is solely responsible for the
                                    use to which it puts the Site and all the results and information it obtains from it
                                    and
                                    that all warranties, conditions, undertakings, representations and terms whether
                                    expressed or implied, statutory or otherwise are hereby excluded to the fullest
                                    extent
                                    permitted by law (save that nothing in this Agreement shall waive or exclude the
                                    normal
                                    pre-contractual duty of disclosure applicable to contracts of insurance and any
                                    other
                                    specific terms or warranties that may apply to any contract of insurance which is
                                    concluded through the Site).</p>
                            </li>
                            <li class="inner-list-item">
                                <p>Save in respect of liability for death or personal injury
                                    arising out of negligence, HGHL hereby disclaims and excludes to the fullest extent
                                    permitted by law all liability for any loss or damage whatsoever and howsoever
                                    incurred
                                    including any consequential, special, secondary or indirect loss or damage or any
                                    damage
                                    to goodwill or profits or any loss of anticipated savings incurred by the Insurer,
                                    whether arising in tort, contract or otherwise, and arising out of or in relation to
                                    or
                                    in connection with the Insurer's access to or use of or inability to use the Site,
                                    or in
                                    connection with any failure of performance, error, omission, interruption, defect,
                                    delay
                                    in operation or transmission, computer virus or line or system failure even if HGHL
                                    or
                                    its employees or agents are advised of the possibility of such damages, losses or
                                    expenses.</p>
                            </li>
                            <li class="inner-list-item">
                                <p>Whilst HGHL takes care to ensure that the standard of the Site
                                    remains high and to maintain the continuity of it, the Internet is not always a
                                    stable
                                    medium, and errors, omissions, interruptions of service and delays may occur at any
                                    time. As a result, HGHL does not accept any ongoing obligation or responsibility to
                                    operate the Site (or any particular part of it).</p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Variation of Content
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>HGHL reserves the right in its absolute discretion at any time
                                    and without notice to remove, amend or vary any of the content which appears on any
                                    page
                                    of the Site.</p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Contribution of your Content
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>Where the Insurer contributes, without limitation, any data,
                                    databases, graphics, logos, research, information, opinions, statements or other
                                    material (together “Insurer Content”) to HGHL for inclusion through the Site, the
                                    Insurer shall contribute Insurer Content:</p>
                            </li>
                            <li class="inner-list-item">
                                <p>in any of the formats HGHL has communicated to the Insurer; and</p>
                            </li>
                            <li class="inner-list-item">
                                <p>using such technology as HGHL approves in writing.
                                </p>
                            </li>
                            <li class="inner-list-item">
                                <p>The Insurer warrants and represents to HGHL that:
                                </p>
                            </li>
                            <li class="inner-list-item">
                                <p>it is the owner of or otherwise has the right to provide the
                                    Insurer Content to HGHL for distribution through the Site;
                                </p>
                            </li>
                            <li class="inner-list-item">
                                <p>HGHL's use of the Insurer Content in accordance with this
                                    Agreement shall not infringe the intellectual property rights of any third party;
                                </p>
                            </li>
                            <li class="inner-list-item">
                                <p>the Insurer Content shall not contain any libellous or
                                    slanderous statements; and
                                </p>
                            </li>
                            <li class="inner-list-item">
                                <p>it has obtained all relevant authorisations and complied with
                                    all applicable laws, government rules and regulations and industry codes necessary
                                    for
                                    it to distribute the Insurer Content through the Site including, without limitation,
                                    those with respect to the provision of financial services.
                                </p>
                            </li>
                            <li class="inner-list-item">
                                <p>Additionally, the Insurer grants HGHL an exclusive,
                                    royalty-free, worldwide licence to receive, store, copy and distribute the Insurer
                                    Content.
                                </p>
                            </li>
                            <li class="inner-list-item">
                                <p>HGHL shall take reasonable steps to put appropriate security
                                    measures in place to safeguard the integrity of the Insurer Content however, the
                                    Insurer
                                    acknowledges that HGHL shall not under any circumstances be liable to the Insurer
                                    for
                                    any breach of security in relation to the Insurer Content.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Copyright
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>All design, text, graphics and the selection or arrangement
                                    thereof in the pages of the Site and the screens displaying the pages of the Site
                                    are the copyright of HGHL unless otherwise indicated.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Trademarks
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>The trade marks, logos and services marks ("Marks") displayed on
                                    the Site are the property of HGHL or other third parties. The Insurer is not
                                    permitted
                                    to use these Marks without the prior written consent of HGHL or the relevant third
                                    party
                                    proprietor of the Marks and the Insurer acknowledges that any such use may
                                    constitute an
                                    infringement of the relevant proprietor's rights.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Links
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>The Site may contain links to third party web sites. Access to
                                    any other web site linked to the Site is at the Insurer's own risk and HGHL is not
                                    responsible for the accuracy or reliability of any information, data, opinions,
                                    advice
                                    or statements made on any such third party web site or the security of any link or
                                    communication with such sites. HGHL reserves the right to terminate a link to a
                                    third
                                    party site at any time. The fact that HGHL provides a link to a third party web site
                                    does not mean that HGHL endorses, authorises or sponsors that web site nor does it
                                    mean
                                    that HGHL is affiliated with the third party web site's owners or sponsors. HGHL
                                    provides these links merely as a convenience for those using the Site.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Privacy of Personal Data
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>The Insurer acknowledges that HGHL is entitled to handle any
                                    personal information the Insurer supplies to HGHL. HGHL only uses the Insurer's
                                    personal
                                    data for the initial registration process, to allow HGHL to process and respond to
                                    any
                                    enquiries the Insurer may have and to contact the Insurer.
                            </li>
                            <li class="inner-list-item">
                                <p>HGHL may also collect information automatically about the
                                    Insurer's visit to the Site. The information obtained in this way is used purely in
                                    an
                                    aggregate form. HGHL uses this type of information for statistical purposes in order
                                    to
                                    help HGHL administer, develop and improve the Site and HGHL may provide such
                                    aggregate
                                    information to third parties. Such types of information will not include personal
                                    information that can be used to identify or locate an individual.
                            </li>
                            <li class="inner-list-item">
                                <p>HGHL may disclose the Insurer's personal data to third parties
                                    for the purpose of providing services the Insurer has requested, as part of the
                                    process
                                    of sale of HGHL's business, or where legitimately requested for legal or regulatory
                                    purposes, as part of legal proceedings or prospective legal proceedings. From time
                                    to
                                    time, these third parties may be located outside the European Economic Area in
                                    countries
                                    which do not have the same standards of protection for personal data as the United
                                    Kingdom.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Entire agreement
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>Save in the case of fraud or fraudulent concealment, each party
                                    acknowledges that:
                                </p>
                                <ol class="inner-list">
                                    <li class="inner-list-item">
                                        <p>this Agreement together with any other documents expressly referred to in it
                                            (together the "Contract") constitutes the entire and only agreement between
                                            the
                                            parties relating to the subject matter of the Contract; and</p>
                                    </li>
                                    <li class="inner-list-item">
                                        <p>it has not been induced to enter into the Contract in reliance on, nor has it
                                            been given, any representation or other statement of any nature whatsoever
                                            other
                                            than those set out in the Contract.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Variation
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>This Agreement may be varied only by a document signed by HGHL.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Waiver
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>No breach of any provision of this Agreement shall be waived or
                                    discharged except with the express written consent of the parties.
                            </li>
                            <li class="inner-list-item">
                                <p>
                                    No failure or delay by a party to exercise any of its rights under this Agreement
                                    shall
                                    operate as a waiver thereof and no single or partial exercise of any such right
                                    shall
                                    prevent any other or further exercise of that or any other right.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Invalidity
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>If any provision of this Agreement is or becomes for any reason
                                    whatsoever invalid, illegal or unenforceable it shall be divisible from this
                                    Agreement
                                    and shall be deemed to be deleted from it and the validity of the remaining
                                    provisions
                                    shall not be affected in any way.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Notices
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>Notices shall be deemed to be served in the case of HGHL
                                    communicating with the Insurer, when HGHL sends an e-mail to the e-mail address
                                    specified by the Insurer during the registration process and in the case of the
                                    Insurer
                                    communicating with HGHL, when delivered or posted to HGHL's offices at One
                                    Creechurch
                                    Place, London, EC3A 5AF for the attention of the Managing Director.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Rights of Third Parties
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>Save for any rights or benefits expressly conferred on HGHL, its
                                    affiliates or any of HGHL's contributors to the Site under this Agreement, nothing
                                    in
                                    this Agreement confers or purports to confer on any third party any benefit or right
                                    to
                                    enforce any term of this Agreement and the provisions of the Contracts (Rights of
                                    Third
                                    Parties) Act 1999 (as amended or modified from time to time) are expressly excluded.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="list-item">Governing Law
                        <ol class="inner-list">
                            <li class="inner-list-item">
                                <p>HGHL makes no representation that the content or materials on
                                    the Site are appropriate or available for use in other jurisdictions outside of the
                                    United Kingdom. Access to the Site's content or its materials from jurisdictions
                                    where
                                    such access is illegal is prohibited. If the Insurer chooses to access the Site from
                                    other jurisdictions, the Insurer does so at its own initiative and risk and the
                                    Insurer
                                    is responsible for compliance with all relevant local laws.
                            </li>
                            <li class="inner-list-item">
                                <p>
                                    This Agreement and any dispute or claim whatsoever relating to its formation shall
                                    be
                                    governed by and interpreted in accordance with English law and the parties
                                    irrevocably
                                    agree that the English courts shall have exclusive jurisdiction.
                                </p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <footer class="footer--main">
        <div class="container">
            <div class="row">
                <div class="copyright col"><span>© 2023 Howden.</span></div>
                <nav class="footer-nav col">
                    <ul class="footer-nav__list">
                        <li class="footer-nav__list-item"><a [routerLink]="['/cookies-policy']" target="_blank"
                                class="nuxt-link-exact-active nuxt-link-active footer-link">Cookie Policy</a>
                        </li>
                        <li class="footer-nav__list-item"><a class="footer-link"
                                href="https://www.hyperiongrp.com/news/" target="_blank">News</a></li>
                        <li class="footer-nav__list-item"><a href="https://www.linkedin.com/company/hyperion-x/"
                                target="_blank" class="footer-nav__icon footer-link"><svg aria-hidden="true"
                                    focusable="false" data-prefix="fab" data-icon="linkedin" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    class="svg-inline--fa fa-linkedin fa-w-14">
                                    <path fill="currentColor"
                                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                                        class=""></path>
                                </svg></a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </footer>
</div>