<div class="session-notification">
    <mat-dialog-content class="mat-typography">
        <p>Session is going to Expire</p>
        <p>Click on OK to continue in this session</p>
    </mat-dialog-content>

    <mat-dialog-actions class="session-notification-dialog-action-buttons" align="end">
        <button mat-button (click)="refreshSession('cancel')">Cancel</button>
        <button mat-button class="session-refresh-button" (click)="refreshSession('ok')" cdkFocusInitial>OK</button>
    </mat-dialog-actions>
</div>