import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../services/filter/filter.service';
import * as moment from 'moment';
import { DashletService } from '../services/dashlet/dashlet.service';
@Pipe({
  name: 'startAndEndDate'
})
export class StartAndEndDatePipe implements PipeTransform {
  constructor(private filterService: FilterService, private dashletService: DashletService) { }
  transform(value: any, chipData: any, ...args: unknown[]): unknown {
    let formattedText = ''
    if (chipData.name == "TimePeriod") {
      let globalPayload = this.dashletService.globalPayload;
      let sortedyearList = globalPayload.timePeriodValues != null ? globalPayload.timePeriodValues.split(",").sort() : [];
      if (globalPayload.TimePeriod == 'Last 365 Days') {
        return formattedText = `${moment(globalPayload.startDate).format("MMM YYYY")} - ${moment(globalPayload.endDate).format("MMM YYYY")}`
      } else if (globalPayload.TimePeriod == 'FY') {
        if (sortedyearList.length == 1 && globalPayload.startDate == '' && globalPayload.endDate == '') {
          return formattedText = `Oct ${sortedyearList[0] - 1} - Sept ${sortedyearList[sortedyearList.length - 1]}`
        } else if (sortedyearList.length > 1 && globalPayload.startDate == '' && globalPayload.endDate == '') {
          return formattedText = `FY - ${sortedyearList.map(item => { return item.slice(-2) }).join(",")}`
        } else if (globalPayload.timePeriodValues == null) {
          return formattedText = `${moment(globalPayload.startDate).format("MMM YYYY")} - ${moment(globalPayload.endDate).format("MMM YYYY")}`
        } else if (sortedyearList.length > 0 && globalPayload.endDate !== '') {
          return formattedText = `FY - ${sortedyearList.map(item => { return item.slice(-2) }).join(",")},${this.filterService.yearList[0].toString().slice(-2)}`
        }
      }
      else if (globalPayload.TimePeriod == 'CY') {
        if (sortedyearList.length == 1 && globalPayload.startDate == '' && globalPayload.endDate == '') {
          return formattedText = `Jan ${sortedyearList[0]} - Dec ${sortedyearList[sortedyearList.length - 1]}`
        } else if (sortedyearList.length > 1 && globalPayload.startDate == '' && globalPayload.endDate == '') {
          return formattedText = `CY - ${sortedyearList.map(item => { return item.slice(-2) }).join(",")}`
        } else if (globalPayload.timePeriodValues == null) {
          return formattedText = `${moment(globalPayload.startDate).format("MMM YYYY")} - ${moment(globalPayload.endDate).format("MMM YYYY")}`
        } else if (sortedyearList.length > 0 && globalPayload.endDate !== '') {
          return formattedText = `CY - ${sortedyearList.map(item => { return item.slice(-2) }).join(",")},${this.filterService.yearList[0].toString().slice(-2)}`
        }
      }
      else {
        return formattedText = `${moment(globalPayload.startDate).format("MMM YYYY")} - ${moment(globalPayload.endDate).format("MMM YYYY")}`
      }
    } else {
     return  formattedText = value;
    }
    return formattedText;
  }
}
