import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

export interface MatDialogData {
  authService: any;
}

@Component({
  selector: 'app-session-time-out-dialog',
  templateUrl: './session-time-out-dialog.component.html',
  styleUrls: ['./session-time-out-dialog.component.scss']
})
export class SessionTimeOutDialogComponent {
  constructor(public dialogRef: MatDialogRef<SessionTimeOutDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: MatDialogData) {}

  refreshSession(status) {
    if (status === 'cancel') {
      this.data.authService.doLogout();
      this.dialogRef.close();
      // this.data.idle.stop();
    } else {
      // this.data.authService.refreshSession();
      // this.data.authService.setSessionTimer();
      this.dialogRef.close();
      // this.data.idle.stop().reset().start();
    }
  }
}
