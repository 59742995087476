interface FilterMap {
  name: string;
  className?: string;
  child: FilterItemMap[];
  count: number;
}
interface FilterItemMap {
  key: string;
  displayName: string;
  isSingleSelect: boolean;
  fixedValues: boolean;
  type: string;
  filterKey?: string;
  singleSelectDropdown?: boolean

}
export const FILTERS_CONFIG: Array<FilterMap> = [
  {
    name: 'Defaults',
    className: 'col0',
    child: [
      { key: 'StrongerTogetherReason', displayName: 'Stronger Together Reason', isSingleSelect: false, fixedValues: false, type: 'MULTI_SELECTION_REASON' },
      { key: 'StrongerTogether', displayName: 'Stronger Together', isSingleSelect: true, fixedValues: true, type: 'SINGLE_SELECT' },
      {
        key: 'ReportingCurrency',
        filterKey: 'Currency',
        displayName: 'Currency',
        isSingleSelect: true,
        fixedValues: true,
        type: 'CUSTOM_CURRENCY'
      },
      { key: 'RateType', displayName: 'Rate of Exchange', isSingleSelect: true, fixedValues: true, type: 'SINGLE_SELECT' },
      { key: 'DateType', displayName: 'Date Type', isSingleSelect: true, fixedValues: true, type: 'SINGLE_SELECT' },
      { key: 'TimePeriod', displayName: 'Time Period', isSingleSelect: true, fixedValues: true, type: 'CUSTOM_DATE_RANGE' }
    ],
    count: 0
  },
  {
    name: 'Business Units',
    className: 'col1',
    child: [
      { key: 'Region', displayName: 'Howden Pillar', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'Company', displayName: 'Howden Division', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'Division', displayName: 'Howden Business Unit Level 1', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'Team', displayName: 'Howden Business Unit Level 2', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'SubTeam', displayName: 'Howden Business Unit Level 3', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'Base', displayName: 'Howden Business Unit Level 4', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' }

    ],
    count: 0
  },
  {
    name: 'Stronger Together Recipients',
    className: 'col1',
    child: [
    { key: 'PolicyRegion', displayName: 'Recipients Pillar', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
    { key: 'PolicyCompany', displayName: 'Recipients Division', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
    { key: 'PolicyDivision', displayName: 'Recipients Business Unit Level 1', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
    { key: 'PolicyTeam', displayName: 'Recipients Business Unit Level 2', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
    { key: 'PolicySubTeam', displayName: 'Recipients Business Unit Level 3', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
    { key: 'PolicyBase', displayName: 'Recipients Business Unit Level 4', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' }
    ],
    count: 0
  },
  {
    name: 'Stronger Together Producers',
    className: 'col1',
    child: [
    { key: 'DisplayLevel1', displayName: 'Level 1', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
    { key: 'DisplayLevel2', displayName: 'Level 2', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
    { key: 'DisplayLevel3', displayName: 'Level 3', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
    ],
    count: 0
  },
  {
    name: 'Products',
    className: 'col1',
    child: [
      { key: 'Product', displayName: 'Product', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'SubProduct', displayName: 'Sub-Product', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' }
    ],
    count: 0
  },


  {
    name: 'Producer',
    className: 'col2',
    child: [
      { key: 'ProducerGroupName', displayName: 'Producer Group', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      {
        key: 'ProducerSubGroupName',
        displayName: 'Producer Sub Group',
        isSingleSelect: false,
        fixedValues: false,
        type: 'AUTOCOMPLETE_DIV'
      }
    ],
    count: 0
  },
  {
    name: 'Industry',
    className: 'col2',
    child: [
      { key: 'IndustrySector', displayName: 'Industry Sector', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'IndustryType', displayName: 'Industry Type', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' }
    ],
    count: 0
  },
  {
    name: 'Producer Location',
    className: 'col2',
    child: [
      { key: 'ProducerRegion', displayName: 'Producer Region', isSingleSelect: false, singleSelectDropdown: true, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'ProducerCountry', displayName: 'Producer Country', isSingleSelect: false, singleSelectDropdown: true, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'ProducerState', displayName: 'Producer State (Canada & USA)', isSingleSelect: false, singleSelectDropdown: true, fixedValues: false, type: 'AUTOCOMPLETE_DIV' }
    ],
    count: 0
  },
  {
    name: 'Market',
    className: 'col3',
    child: [
      { key: 'PlacingMarket', displayName: 'Placing Market', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      {
        key: 'PlacingSubMarket',
        displayName: 'Placing Sub-market',
        isSingleSelect: false,
        fixedValues: false,
        type: 'AUTOCOMPLETE_DIV'
      }
    ],
    count: 0
  },
  {
    name: 'Insured Location',
    className: 'col3',
    child: [
      { key: 'InsuredContinent', displayName: 'Insured Region', isSingleSelect: false, singleSelectDropdown: true, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'InsuredCountry', displayName: 'Insured Country', isSingleSelect: false, singleSelectDropdown: true, fixedValues: false, type: 'AUTOCOMPLETE_DIV' }
    ],
    count: 0
  },
  {
    name: 'Client Location',
    className: 'col3',
    child: [
      { key: 'ClientRegion', displayName: 'Client Region', isSingleSelect: false, singleSelectDropdown: true, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'ClientCountry', displayName: 'Client Country', isSingleSelect: false, singleSelectDropdown: true, fixedValues: false, type: 'AUTOCOMPLETE_DIV' }
    ],
    count: 0
  },
  {
    name: 'Capacity Markets',
    className: 'col3',
    child: [
      { key: 'CapacityMarket', displayName: 'Capacity Market', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' },
      { key: 'CapacitySubMarket', displayName: 'Capacity Sub Market', isSingleSelect: false, fixedValues: false, type: 'AUTOCOMPLETE_DIV' }
    ],
    count: 0
  }
];

export const FILTER_COUNT_DASHLET_CONFIG = [
  'CrossFilterAllCountBU',
  'CrossFilterAllCountProducerState',
  'CrossFilterAllCountOther',
  'CrossFilterAllCountProducer',
  'CrossFilterAllCountProdRegion',
  'CrossFilterCountClientRegion'
];

export const RENEWAL_FILTERS = {
  DateType: {
    IsSingleSelect: true,
    DisplayName: 'Time Period',
    Key: 'DateType',
    Data: [
      {
        Name: 'Renewal Date',
        Key: 'RenewalDate',
        IsDefault: true
      }
    ]
  },
  TimePeriod: {
    IsSingleSelect: true,
    DisplayName: 'Date Range',
    Key: 'TimePeriod',
    Data: [
      {
        Name: 'Next days',
        Key: 'Next days',
        IsDefault: true
      },
      {
        Name: 'Last 365 Days',
        Key: 'Last 365 Days',
        IsDefault: false
      },
      {
        Name: 'Calendar Year',
        Key: 'CY',
        IsDefault: false
      },
      {
        Name: 'Financial Year',
        Key: 'FY',
        IsDefault: false
      },
      {
        Name: 'Custom',
        Key: 'Custom',
        IsDefault: false
      },
      {
        Name: 'Months',
        Key: 'Months',
        IsDefault: false
      }
    ]
  }
};

export const OTHER_PAGE_FILTERS = {
  TimePeriod: {
    IsSingleSelect: true,
    DisplayName: 'Date Range',
    Key: 'TimePeriod',
    Data: [
      {
        Name: 'Last 12 months',
        Key: 'Last 365 Days',
        IsDefault: true
      },
      {
        Name: 'Calendar Year',
        Key: 'CY',
        IsDefault: false
      },
      {
        Name: 'Financial Year',
        Key: 'FY',
        IsDefault: false
      },
      {
        Name: 'Fin. Year To Date',
        Key: 'FYTD',
        IsDefault: false
      },
      {
        Name: 'Custom',
        Key: 'Custom',
        IsDefault: false
      }
      // {
      //   Name: 'All Values',
      //   Key: 'All Values',
      //   IsDefault: false
      // }
    ]
  },
  DateType: {
    IsSingleSelect: true,
    DisplayName: 'Date Type',
    Key: 'DateType',
    Data: [
      {
        Name: 'Brokerage Date',
        Key: 'BrokerageDate',
        IsDefault: true
      },
      {
        Name: 'Inception Date',
        Key: 'InceptionDate',
        IsDefault: false
      },
      {
        Name: 'Entry Date',
        Key: 'EntryDate',
        IsDefault: false
      },
      {
        Name: 'Renewal Date',
        Key: 'RenewalDate',
        IsDefault: false
      }
    ]
  },
  StrongerTogether: {
    IsSingleSelect: true,
    DisplayName: 'Stronger Together',
    Key: 'StrongerTogether',
    Data: [
      {
        Name: 'Off',
        Key: 'Off',
        IsDefault: true
      },
      {
        Name: 'On',
        Key: 'On',
        IsDefault: false
      },
    ]
  },
  StrongerTogetherReason: {
    IsSingleSelect: false,
    DisplayName: 'Stronger Together Reason',
    Key: 'StrongerTogetherReason',
    Data: [
      {
        Name: 'Total',
        Key: 'Total',
        IsDefault: false
      },
      {
        Name: 'DiffBU',
        Key: 'DiffBU',
        IsDefault: false
      },
      {
        Name: 'Howden Client/TP',
        Key: 'HowdenClientTP',
        IsDefault: false
      },
    ]
  }
};

const date = new Date();
const month = date.getMonth() + 1;
const year = date.getFullYear();
const fullFinancialYear = (month >= 10 && month <= 12) ? year + 1 : year;
const financialYear = Number(fullFinancialYear.toString().slice(-2));
const previousFinancialYear = financialYear - 1;

export const FIXED_FILTERVALUES_MOCK = {
  RateType: {
    IsSingleSelect: true,
    DisplayName: 'Rate of Exchange',
    Key: 'RateType',
    Data:
    [
      {
        Name: 'Month End',
        Key: 'Month End',
        IsDefault: false
      },
      {
        Name: 'HIBL Annual Budget FY' + previousFinancialYear,
        Key: 'HIBL Annual Budget FY' + previousFinancialYear,
        IsDefault: false
      },
      {
        Name: 'Group Annual Budget FY' + previousFinancialYear,
        Key: 'Group Annual Budget FY' + previousFinancialYear,
        IsDefault: false
      },
      {
        Name: 'HIBL Annual Budget FY' + financialYear,
        Key: 'HIBL Annual Budget FY' + financialYear,
        IsDefault: false
      },
      {
        Name: 'Group Annual Budget FY' + financialYear,
        Key: 'Group Annual Budget FY' + financialYear,
        IsDefault: true
      }
      // {
      //   Name: 'Project',
      //   Key: 'Project',
      //   IsDefault: false
      // }
    ]
  },
  ReportingCurrency: {
    IsSingleSelect: true,
    DisplayName: 'Currency',
    Key: 'ReportingCurrency',
    Data: [
      {
        Name: 'USD',
        Key: 'USD',
        IsDefault: true
      },
      {
        Name: 'GBP',
        Key: 'GBP',
        IsDefault: false
      },
      {
        Name: 'EUR',
        Key: 'EUR',
        IsDefault: false
      },
      {
        Name: 'Other',
        Key: 'Other',
        IsDefault: false
      }
    ]
  },
  // IsLuxTransaction: {
  //   IsSingleSelect: true,
  //   DisplayName: 'Is Howden Specialty Luxembourg Transaction',
  //   Key: 'IsLuxTransaction',
  //   Data: [
  //     {
  //       Name: 'Default',
  //       Key: 'Default',
  //       IsDefault: true
  //     },
  //     {
  //       Name: 'No',
  //       Key: 0,
  //       IsDefault: false
  //     },
  //     {
  //       Name: 'Yes',
  //       Key: -1,
  //       IsDefault: false
  //     }
  //   ]
  // },
  ...OTHER_PAGE_FILTERS
};

export const FIXED_FILTERVALUES_MOCK_ONLY_PREV = {
  RateType: {
    IsSingleSelect: true,
    DisplayName: 'Rate of Exchange',
    Key: 'RateType',
    Data:
    [
      {
        Name: 'Month End',
        Key: 'Month End',
        IsDefault: false
      },
      {
        Name: 'HIBL Annual Budget FY' + previousFinancialYear,
        Key: 'HIBL Annual Budget FY' + previousFinancialYear,
        IsDefault: false
      },
      {
        Name: 'Group Annual Budget FY' + previousFinancialYear,
        Key: 'Group Annual Budget FY' + previousFinancialYear,
        IsDefault: true
      }
    ]
  },
  ReportingCurrency: {
    IsSingleSelect: true,
    DisplayName: 'Currency',
    Key: 'ReportingCurrency',
    Data: [
      {
        Name: 'USD',
        Key: 'USD',
        IsDefault: true
      },
      {
        Name: 'GBP',
        Key: 'GBP',
        IsDefault: false
      },
      {
        Name: 'EUR',
        Key: 'EUR',
        IsDefault: false
      },
      {
        Name: 'Other',
        Key: 'Other',
        IsDefault: false
      }
    ]
  },
  // IsLuxTransaction: {
  //   IsSingleSelect: true,
  //   DisplayName: 'Is Howden Specialty Luxembourg Transaction',
  //   Key: 'IsLuxTransaction',
  //   Data: [
  //     {
  //       Name: 'Default',
  //       Key: 'Default',
  //       IsDefault: true
  //     },
  //     {
  //       Name: 'No',
  //       Key: 0,
  //       IsDefault: false
  //     },
  //     {
  //       Name: 'Yes',
  //       Key: -1,
  //       IsDefault: false
  //     }
  //   ]
  // },
  ...OTHER_PAGE_FILTERS
};

export const FIXED_FILTERVALUES_MOCK_ONLY_CURR = {
  RateType: {
    IsSingleSelect: true,
    DisplayName: 'Rate of Exchange',
    Key: 'RateType',
    Data:
    [
      {
        Name: 'Month End',
        Key: 'Month End',
        IsDefault: false
      },
      {
        Name: 'HIBL Annual Budget FY' + financialYear,
        Key: 'HIBL Annual Budget FY' + financialYear,
        IsDefault: false
      },
      {
        Name: 'Group Annual Budget FY' + financialYear,
        Key: 'Group Annual Budget FY' + financialYear,
        IsDefault: true
      }
    ]
  },
  ReportingCurrency: {
    IsSingleSelect: true,
    DisplayName: 'Currency',
    Key: 'ReportingCurrency',
    Data: [
      {
        Name: 'USD',
        Key: 'USD',
        IsDefault: true
      },
      {
        Name: 'GBP',
        Key: 'GBP',
        IsDefault: false
      },
      {
        Name: 'EUR',
        Key: 'EUR',
        IsDefault: false
      },
      {
        Name: 'Other',
        Key: 'Other',
        IsDefault: false
      }
    ]
  },
  // IsLuxTransaction: {
  //   IsSingleSelect: true,
  //   DisplayName: 'Is Howden Specialty Luxembourg Transaction',
  //   Key: 'IsLuxTransaction',
  //   Data: [
  //     {
  //       Name: 'Default',
  //       Key: 'Default',
  //       IsDefault: true
  //     },
  //     {
  //       Name: 'No',
  //       Key: 0,
  //       IsDefault: false
  //     },
  //     {
  //       Name: 'Yes',
  //       Key: -1,
  //       IsDefault: false
  //     }
  //   ]
  // },
  ...OTHER_PAGE_FILTERS
};
