// import { OktaConfig } from '@okta/okta-angular/esm2015/src/okta/models/okta.config'

export class AppConfig {
  production: boolean;
  apiUrl: string;
  useMockData: boolean;
  appInsights: AppInsight;
  googleAnalytics: GoogleAnalytics;
  AADConfig: AADConfig;
}

export class AppInsight {
  instrumentationKey: string;
}

export class GoogleAnalytics {
  trackingCode: string;
  trackingOn: boolean;
}


export class AADConfig {
  clientId: string;
  authority: string;
  redirectUri: string;
  apiScope: string;
}

export class countryNames {
  US: string;

}