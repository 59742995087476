export class FilterItem {
  public key;
  public displayName;
  public chipName;
  public filtervalue;
  public filterkey;
  public filterChipName
  constructor(value, public disabled: boolean = false, public singleSelect: boolean = false, isCurrencyFilter: boolean = false) {
    if (typeof value === 'string') {
      this.key = this.displayName = value;
    } else {
      this.key = value.Key;
      if (isCurrencyFilter) {
        this.displayName = `${value.Key} - ${value.Description}`;
      } else {
        this.displayName = value.Name;
      }
    }

    if (isCurrencyFilter) {
      this.chipName = this.key;
    } else {
      this.chipName = this.key === 'Next days' ? 'Next days - 180' : this.displayName;
    }
    this.filtervalue = this.displayName;
    this.filterkey = this.key;
    this.filterChipName = this.chipName;
  }
}

export class FilterChip {
  public selected: boolean;
  public value: Array<FilterItem>;
  constructor(
    public name: string,
    public displayName: string,
    public isSingleSelect: boolean,
    value: string | object,
    private totalRecords: number = 0,
    public removable: boolean = true
  ) {
    this.selected = !this.removable;
    this.value = value == undefined ? null : [new FilterItem(value)];
  }
}

export class FilterConfigItem {
  public type: string;
  constructor(public key: string, public displayName: string, public isSingleSelect: boolean, public fixedValues: boolean) {
    if (key === 'TimePeriod') {
      this.type = 'CUSTOM_DATE_RANGE';
    } else if (isSingleSelect) {
      this.type = 'SINGLE_SELECT';
    } else {
      this.type = 'AUTOCOMPLETE_DIV';
    }
  }
}
