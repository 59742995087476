/*
Below JSON object will have the api address that will be used across the project
It should be a key value pair
with key being used in api methods
and value being used for api service to make relevant calls
E.g: "userDetails": "UserConfig/GetUserAccess"
*/

export const URL_LIST = {
  fixedFilters: 'Filters/GetFiltersForUser',
  defaultFilters: 'Filters/GetDefaultFilters?dashboard=RKH%20Insights',
  filterConfig: 'Filters/GetUIFilterConfiguration?dashboard=RKH%20Insights',
  dashlet: 'MI/GetDashboardData',
  riskDetailsData: 'RiskDetails/GetRiskDetailsData',
  riskDetailsColumns: 'RiskDetails/GetRiskDetailsFields?page=RiskDetails',
  exportToExcel: 'MI/ExportToExcel',
  exportGlobalExcel: 'MI/GlobalExportExcel',
  riskDetailsExportToExcel: 'RiskDetails/ExportToExcel',
  footerInfo: 'MI/GetFooterInformation',
  reportIssue: '/User/ReportIssue',
  loginTime: 'User/GetUserInfo',
  accessLevel: 'User/GetUserBUAccess',
  savedFiltersList: 'Filters/GetFilterSetsForUser',
  saveNewFilter: 'Filters/SaveOrUpdateFilterSetForUser',
  deleteSavedFilter: 'Filters/DeleteFilterSetForUser',
  submitFeedback: 'User/SubmitFeedback',
  monthsListForRenewal: 'Filters/GetMonthsListForRenewal',
  filterCount: 'Filters/GetGroupedFiltersCount',
  yearList: 'Filters/GetYearsList',
  userMDSDetails: 'User/GetUserMDSId',
  emailGlobalExcel: 'MI/ExportExcelToEmail',
  exportDatabaseExcelChunks: 'MI/DownloadBlobAsync',

};
