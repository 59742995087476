import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/app.config';

fetch('/assets/appconfig.json', { method: 'get' }).then(response => {
  response
    .json()
    .then(settings => {
      const booleanProperties = ['useMockData', 'production'];
      booleanProperties.forEach((value, index, array) => {
        settings[array[index]] = settings[array[index]] == 'true';
      });
      if (settings.production) {
        enableProdMode();
      }
      platformBrowserDynamic([
        {
          provide: AppConfig,
          useValue: settings
        },
      ]).bootstrapModule(AppModule);
    })
    .catch(err => console.log(err));
});
