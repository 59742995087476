import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfig } from './app.config';
import { NoteService } from './shared/services/notes/note.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { GoogleAnalytics, Hotjar } from '@JFrogPackage/analytics-lib';
import { BaseService } from './shared/services/base/base.service';
import { PAGES_INFO } from './shared/constants/common-constants';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { AppInsightsService } from './shared/services/app-insights.service';

/* eslint-disable */
declare let gtag: Function;
/* eslint-enable */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Market Intelligence';
  dashboardRoutes: any;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private noteService: NoteService,
    private router: Router,
    private appConfig: AppConfig,
    private analyticsService: AnalyticsService,
    private baseService: BaseService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private appInsights: AppInsightsService,


  ) {
    this.dashboardRoutes = PAGES_INFO;
    // Adding google analytics
    if (this.parseToBoolean(this.appConfig.googleAnalytics.trackingOn)) {
      GoogleAnalytics.addGA(this.appConfig.googleAnalytics.trackingCode, [
        'userId',
        'isExternal',
        'pageName',
        'exportType',
        'exportedAt',
        'dashlets'
      ]);
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (localStorage.getItem('userId')) {
            this.baseService.isDefaultPageView.next(true);
            GoogleAnalytics.addCustomGAEvents('page_view', {
              page_title: event.urlAfterRedirects,
              page_location: event.urlAfterRedirects,
              userId: localStorage.getItem('userId'),
              pageName: event.urlAfterRedirects,
              userEmail: localStorage.getItem('userEmail')
            });
          } else {
            const invalidRoutes = this.dashboardRoutes.filter(item => item === event.urlAfterRedirects);
            if (invalidRoutes.length) {
              return;
            } else {
              GoogleAnalytics.addCustomGAEvents('page_view', {
                page_title: event.urlAfterRedirects,
                page_location: event.urlAfterRedirects,
                pageName: event.urlAfterRedirects,
                userEmail: localStorage.getItem('userEmail')
              });
            }
          }
        }
      });
    }

    // Adding Hotjar
    if (this.parseToBoolean(this.appConfig['hotJar'].trackingOn)) {
      Hotjar.addHotJar(this.appConfig['hotJar'].trackingCode);
    }
  }

  ngOnInit() {
    // show notifications for the browsers

    const isChrome = navigator.userAgent.indexOf('Edg') === -1 && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome && window.outerWidth < 1920) {
      this.noteService.showResolutionNotification();
    }
    if (!isChrome) {
      this.noteService.showBrowserNotification();
    }

    this.performAADCode()
  }

  performAADCode() {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_NETWORK_START),
        takeUntil(this._destroying$))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        if (payload.account) {
          var localStorageMail = "";
          if (localStorage.getItem('userEmail') !== null && localStorage.getItem('userEmail') !== undefined) {
            localStorageMail = localStorage.getItem('userEmail');
          }

          var emailFromPayload = payload.account.username;
          localStorage.setItem('userEmail', payload.account.username);
          localStorage.setItem('userProfile', JSON.stringify({
            "firstName": payload.account.name,
            "lastName": "",
            "email": payload.account.username,
            "login": payload.account.username
          }));


          var dateNowMilliseconds = new Date().getTime();
          if (localStorage.getItem("lastEventDate") !== null && localStorage.getItem("lastEventDate") !== undefined) {
            if (emailFromPayload === localStorageMail) {
              var lastEventDateMilliseconds = parseInt(localStorage.getItem("lastEventDate"));
              if ((dateNowMilliseconds - lastEventDateMilliseconds) > 1000) {
                this.handleTracking();
              }
            } else {
              this.handleTracking();
            }
            localStorage.setItem("lastEventDate", dateNowMilliseconds.toString());
          } else {
            this.handleTracking();
            localStorage.setItem("lastEventDate", dateNowMilliseconds.toString());
          }
          this.baseService.isLoginSuccess.next(true);
        }
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  parseToBoolean(value) {
    if (value) {
      return value.toString().toLowerCase() === 'true';
    } else {
      return false;
    }
  }

  handleTracking() {
    let email = localStorage.getItem("userEmail");
    this.appInsights.setAuthenticatedUserContext(email);
    localStorage.setItem('loggedIn', 'DONE');
    this.appInsights.logPageView("Login", "/login");
    return true;
  }
}
