import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { URL_LIST } from '../constants/url-list';

import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { AuthService } from './auth.service';
import * as moment from 'moment';
declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router, private appConfig: AppConfig, private authService: AuthService) { }

  private ENV_URL = this.appConfig.apiUrl;
  private urlList = URL_LIST;

  get(type, payload?): Observable<any> {
    return this.http.get(this.getUrl(type, payload)).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleError(err))
    );
  }

  post(type, req): Observable<any> {
    return this.http.post(this.getUrl(type), req).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleError(err))
    );
  }

  put(type, req): Observable<any> {
    return this.http.put(this.getUrl(type), req).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleError(err))
    );
  }

  downloadFile(type, fileName, req, params?): Observable<any> {
    return this.http.post(this.getUrl(type, params), req, { responseType: 'blob' }).pipe(
      map(res => this.handleFileResponse(res, type === 'exportDatabaseExcelChunks' ? `${moment().format('DD-MM-YYYY')}_Dashlets_Data` : fileName)),
      catchError(err => this.handleError(err))
    );
  }

  getUrl(type, payload?) {
    let url = this.ENV_URL + this.urlList[type];
    if (payload) {
      url += this.getQueryString(payload);
    }
    return url;
  }

  getQueryString(payload) {
    let query = Object.keys(payload)
      .map(k => {
        return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
      })
      .join('&');
    query = `?${query}`;
    return query;
  }

  // Handle Response for Download File
  handleFileResponse(response, fileName) {
    // this.authService.setSessionTimer();
    // For Custom Server Error
    if (response.error) {
      this.handleCustomServerError(response);
    } else {
      const file = new Blob([response], { type: response.type });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(file, fileName);
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = fileName;
        a.click();
      }
    }
  }

  // Handle Response from the API
  handleResponse(response) {
    // this.authService.setSessionTimer();
    // For Custom Server Error
    if (response && response.error) {
      this.handleCustomServerError(response);
    } else {
      return response;
    }
  }

  // Handle Error from the API
  handleError(error) {
    // this.authService.setSessionTimer();
    // For Authorised Error when Session Timed Out
    if (error.status === 401) {
      this.authService.doLogout();
      return [];
    } else {
      const errMsg = error.message || 'Server Error';
      return throwError(errMsg);
    }
  }

  // Handle Custom Server Error
  handleCustomServerError(response) {
    // TODO: Format the Custom Error as per requirement Later
    // const error = { error: response.error, message: response.message };
    throw new Error(response.message);
  }
}
