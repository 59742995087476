import { Injectable } from '@angular/core';
import { NoteTemplateComponent } from '../../components/note-template/note-template.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  isLowResolution = false;
  constructor(private _snackBar: MatSnackBar) {
    if (window.outerWidth < 1920) {
      this.isLowResolution = true;
    }
  }

  showResolutionNotification() {
    if (localStorage.getItem('resolutionNoteVisible') === 'TRUE') {
      return;
    }
    this.showNote('Please note that 1920x1080 is the recommended resolution for this application', 'top', 'browser-snackbar', 15).subscribe(
      data => {
        if (!data.dismissedByAction) {
          return;
        }
        localStorage.setItem('resolutionNoteVisible', 'TRUE');
      }
    );
  }

  showBrowserNotification() {
    if (localStorage.getItem('browserNoteVisible') === 'TRUE') {
      return;
    }
    this.showNote(
      'Please note that Google Chrome is the recommended browser for this application' +
        (this.isLowResolution ? ' and recommended resolution is 1920' : ''),
      'top',
      'browser-snackbar',
      15
    ).subscribe(data => {
      if (!data.dismissedByAction) {
        return;
      }
      localStorage.setItem('browserNoteVisible', 'TRUE');
    });
  }

  showRenewalNote() {
    if (localStorage.getItem('renewalNoteVisible') === 'TRUE') {
      return;
    }
    this.showNote(
      `Please note: The renewal list use a different filtering on the Date, it has changed from Brokerage Date to Renewal Date,
    also the date range has changed from 'last 365 days' to 'next 180 days' by default.`,
      'top',
      'renewal-snackbar'
    ).subscribe(data => {
      if (!data.dismissedByAction) {
        return;
      }
      localStorage.setItem('renewalNoteVisible', 'TRUE');
    });
  }

  showNote(message, position, parentClass, time = 3) {
    const snackRef = this._snackBar.openFromComponent(NoteTemplateComponent, {
      panelClass: parentClass,
      verticalPosition: position,
      horizontalPosition: 'center',
      data: { message }
    });
    return snackRef.afterDismissed();
  }
}
