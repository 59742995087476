import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-note-template',
  templateUrl: './note-template.component.html',
  styleUrls: ['./note-template.component.scss']
})
export class NoteTemplateComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackRef: MatSnackBarRef<NoteTemplateComponent>) {}

  closeInfo() {
    this.snackRef.dismissWithAction();
  }
}
