import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativeValue'
})
export class NegativeValuePipe implements PipeTransform {
  transform(value: number | string, suffix: string = '', prefix: string = '', type: string = '', decimalFormat: number = 2): string {
    if (value === null) {
      return 'TBC';
    }
    const tempValue = typeof value == 'number' ? value : parseFloat(value);
    const isNegative = tempValue < 0;
    const absValue = Math.abs(tempValue);
    let formattedValue;
    let divisionFormat = 0;
    let extension = '';

    if (suffix === '%') {
      formattedValue = Math.round(absValue);
      decimalFormat = 0;
    } else if (type.toLowerCase() === 'm') {
      divisionFormat = 2;
      decimalFormat = 2; // For all explicit millions, 2 decimal values
      extension = 'm';
    } else if (type.toLowerCase() === 'k') {
      divisionFormat = 1;
      extension = 'k';
    } else if (absValue >= 1e6) {
      divisionFormat = 2;
      extension = 'm';
    } else if (absValue > 1e3) {
      divisionFormat = 1;
      extension = 'k';
    }

    const dividend = Math.pow(10, 3 * divisionFormat);
    const decimalSeparator = Math.pow(10, decimalFormat);
    formattedValue = Math.round((absValue * decimalSeparator) / dividend) / decimalSeparator + '';

    // check for only 1 decimal digit present in million type (0.9 => 0.90, 0 => 0.0, 0.1 => 0.1)
    if (decimalFormat > 0) {
      formattedValue = formattedValue + (formattedValue.indexOf('.') === -1 ? '.' : '');
      const diff = decimalFormat - formattedValue.split('.')[1].length;
      formattedValue = formattedValue + '0'.repeat(diff);
    }

    formattedValue = formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // let returnValue = tempValue > 0 ? `${prefix}${tempValue}${suffix}` : `(${prefix}${tempValue * -1}${suffix})`
    return `${prefix}${formattedValue}${extension}${suffix}`;
  }
}
