import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CallbackGuard } from './shared/guards/callback.guard';
import { AuthGuard } from './shared/guards/auth.gaurd';
import { UnauthorisedComponent } from './pages/unauthorised/unauthorised.component';
import { MsalGuard } from '@azure/msal-angular';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { ExportExcelComponent } from './pages/export-excel/export-excel.component';

export function onAuthRequired({ oktaAuth, router }) {
  // Redirect the user to your custom login page
  router.navigate(['/login']);
}
const routes: Routes = [
  { path: '', canActivate: [MsalGuard], loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'excelExport', component: ExportExcelComponent, canActivate: [MsalGuard] },
  { path: 'unauthorised', component: UnauthorisedComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
