export class SavedFilters {
  public renewalFilters: Array<SavedFilterItem>;
  public nonRenewalFilters: Array<SavedFilterItem>;
  constructor(obj) {
    this.renewalFilters = [];
    this.nonRenewalFilters = [];
    for (const item of obj) {
      if (item['PageName'] === 'Renewal') {
        this.renewalFilters.push(new SavedFilterItem(item));
      } else if (item['PageName'] === 'Producers&Markets') {
        this.nonRenewalFilters.push(new SavedFilterItem(item));
      }
    }
  }
}

class SavedFilterItem {
  public setName: string;
  public filterList: string;
  public filterDisplayList: string;
  public isDefault: boolean;
  constructor(obj) {
    this.setName = obj.FilterSetName;
    this.filterDisplayList = '';
    this.filterList = JSON.parse(obj.FilterSet);
    this.isDefault = obj.IsDefault;
  }
}
